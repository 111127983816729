@import '../sass/mixins';

section {
  width: 100vw;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  position: relative;
  overflow: hidden;
}

.stem {
  height: 100vh;
  width: 4px;
  background: #e8e8e8;
  position: absolute;
  top: 50vh;
  left: calc(50vw - 2px);
  z-index: 0;
  opacity: 0;
  animation-name: animMakeVisible;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;

  &.fixed {
    top: 0;
    position: fixed;
  }

  &.fixedTop {
    top: 0;
    position: fixed;
    height: 50vh;
  }
}

.navArrow {
  width: 35px;
  height: 35px;
  background: #FFF;
  position: fixed;
  z-index: 10000;
  text-align: center;
  border-radius: 100%;

  @include media(mobile) {
    width: 50px;
    height: 50px;
  }

  img {
    height: 20px;
    margin-top: 7.5px;

    @include media(mobile) {
      height: 35px;
    }
  }
}

.navArrowUp {
  top: 10px;
  left: calc(50vw - 17.5px);
  opacity: 0;

  @include media(mobile) {
    left: calc(50vw - 25px);
  }

  &.invisible {
    opacity: 0;
    animation-name: animMakeInvisible;
    animation-duration: 0.5s;
  }

  &.visible {
    display: block;
    opacity: 1;
    animation-name: animMakeVisible;
    animation-duration: 0.5s;

    &:hover {
      cursor: pointer;
    }
  }
}

.navArrowDown {
  bottom: 10px;
  left: calc(50vw - 17.5px);

  @include media(mobile) {
    left: calc(50vw - 25px);
  }

  img.fade {
    opacity: 0.2;
    animation-name: animMakeFade;
    animation-duration: 0.5s;
  }

  &.visible {
    opacity: 1;
    animation-name: animMakeUnfade;
    animation-duration: 0.5s;

    &:hover {
      cursor: pointer;
    }
  }
}

.debug {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 10000;
  background: white;
}

.textDownArrow {
  position: fixed;
  bottom: 50px;
  z-index: 10000;
  width: 100px;
  text-align: center;
  font-size: 0.8em;
  left: calc(50vw - 50px);
  padding: 4px 0 2px 0;
  opacity: 1;
  border-radius: 10px;
  @include white-gradient-bg();

  &:hover {
    cursor: pointer;
  }

  @include media(mobile) {
    bottom: 66px;
  }
}
