@import '../sass/mixins.scss';

.fireworks {
  position: absolute;
  bottom: 0;
  left: calc(50vw - 160px);
  width: 320px;
  height: 568px;
  z-index: 99999;
  display: none;
  pointer-events: none;
  overflow: hidden;

  &.show {
    display: block;
  }

  .fireworksStarLeft,
  .fireworksStarMid,
  .fireworksStarRight,
  .fireworksStreakTop,
  .fireworksSparkles {
    position: absolute;
    animation-fill-mode: forwards;
  }

  .fireworksStarLeft {
    width: 60px;
    height: 60px;
    bottom: -60px;
    left: calc(50% - (23% / 2));
    animation-name: fireworksStarLeft;
    animation-duration: 2s;
    animation-delay: 2s;
  }

  .fireworksStarMid {
    width: 80px;
    height: 80px;
    bottom: -80px;
    left: calc(50% - 40px);
    animation-name: fireworksStarMid;
    animation-duration: 1.5s;
    animation-delay: 2s;
  }

  .fireworksStarRight {
    width: 60px;
    height: 60px;
    bottom: -60px;
    left: calc(50% - (23% / 2));
    animation-name: fireworksStarRight;
    animation-duration: 2s;
    animation-delay: 2s;
  }

  .fireworksStreakTop {
    width: 320px;
    left: 0;
    bottom: 35%;
    opacity: 0;
    animation-name: fireworksStreakTop;
    animation-duration: 2s;
    animation-delay: 2.6s;
  }

  .fireworksSparkles {
    width: 320px;
    height: 320px;
    bottom: -320px;
    left: 0;
    opacity: 0;
    animation-name: fireworksSparkles;
    animation-duration: 2.5s;
    animation-delay: 2s;
  }
}

@keyframes fireworksStarLeft {
  0% {
    bottom: -60px;
    left: calc(50% - 40px);
  }

  50% {
    opacity: 1;
  }

  100% {
    bottom: 50%;
    left: 10%;
    opacity: 0;
  }
}

@keyframes fireworksStarMid {
  0% {
    bottom: -80px;
    left: calc(50% - 40px);
  }

  50% {
    opacity: 1;
  }

  100% {
    bottom: 60%;
    left: calc(50% - 40px);
    opacity: 0;
  }
}

@keyframes fireworksStarRight {
  0% {
    bottom: -60px;
    left: calc(50% - 40px);
  }

  50% {
    opacity: 1;
  }

  100% {
    bottom: 50%;
    left: 70%;
    opacity: 0;
  }
}

@keyframes fireworksStreakTop {
  0% {
    bottom: 35%;
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    bottom: 60%;
    opacity: 0;
  }
}

@keyframes fireworksSparkles {
  0% {
    bottom: -320px;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    bottom: 25%;
    opacity: 0;
  }
}
