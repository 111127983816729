@import '../sass/config.scss';
@import '../sass/mixins.scss';

section.outro {
  text-align: center;

  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    height: calc((var(--vh, 1vh) * 50));
    padding: 0 40px;

    div {
      @include white-gradient-bg();
      padding: 20px 0;
    }
  }

  .message {
    line-height: $line-height-copy;
  }

  .showMyMatches {
    width: 200px;
    left: calc(50vw - 100px);
  }
}
