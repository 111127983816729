section {
  width: 100vw;
  height: 100vh;
  height: calc((1vh * 100));
  height: calc((var(--vh, 1vh) * 100));
  position: relative;
  overflow: hidden; }

.stem {
  height: 100vh;
  width: 4px;
  background: #e8e8e8;
  position: absolute;
  top: 50vh;
  left: calc(50vw - 2px);
  z-index: 0;
  opacity: 0;
  -webkit-animation-name: animMakeVisible;
          animation-name: animMakeVisible;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }
  .stem.fixed {
    top: 0;
    position: fixed; }
  .stem.fixedTop {
    top: 0;
    position: fixed;
    height: 50vh; }

.navArrow {
  width: 35px;
  height: 35px;
  background: #FFF;
  position: fixed;
  z-index: 10000;
  text-align: center;
  border-radius: 100%; }
  @media screen and (min-width: 375px) {
    .navArrow {
      width: 50px;
      height: 50px; } }
  .navArrow img {
    height: 20px;
    margin-top: 7.5px; }
    @media screen and (min-width: 375px) {
      .navArrow img {
        height: 35px; } }

.navArrowUp {
  top: 10px;
  left: calc(50vw - 17.5px);
  opacity: 0; }
  @media screen and (min-width: 375px) {
    .navArrowUp {
      left: calc(50vw - 25px); } }
  .navArrowUp.invisible {
    opacity: 0;
    -webkit-animation-name: animMakeInvisible;
            animation-name: animMakeInvisible;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s; }
  .navArrowUp.visible {
    display: block;
    opacity: 1;
    -webkit-animation-name: animMakeVisible;
            animation-name: animMakeVisible;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s; }
    .navArrowUp.visible:hover {
      cursor: pointer; }

.navArrowDown {
  bottom: 10px;
  left: calc(50vw - 17.5px); }
  @media screen and (min-width: 375px) {
    .navArrowDown {
      left: calc(50vw - 25px); } }
  .navArrowDown img.fade {
    opacity: 0.2;
    -webkit-animation-name: animMakeFade;
            animation-name: animMakeFade;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s; }
  .navArrowDown.visible {
    opacity: 1;
    -webkit-animation-name: animMakeUnfade;
            animation-name: animMakeUnfade;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s; }
    .navArrowDown.visible:hover {
      cursor: pointer; }

.debug {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 10000;
  background: white; }

.textDownArrow {
  position: fixed;
  bottom: 50px;
  z-index: 10000;
  width: 100px;
  text-align: center;
  font-size: 0.8em;
  left: calc(50vw - 50px);
  padding: 4px 0 2px 0;
  opacity: 1;
  border-radius: 10px;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 10%, white 90%, rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 );
  /* IE6-9 */ }
  .textDownArrow:hover {
    cursor: pointer; }
  @media screen and (min-width: 375px) {
    .textDownArrow {
      bottom: 66px; } }

.emoji {
  width: 70px;
  height: 70px;
  z-index: 9999;
  -webkit-transform: scale(1);
          transform: scale(1);
  text-align: center;
  position: relative; }
  @media screen and (min-width: 375px) {
    .emoji {
      width: 100px;
      height: 100px; } }
  .emoji.unselected {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-name: makeBigger;
            animation-name: makeBigger;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s; }
  .emoji.selected {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-animation-name: makeSmaller;
            animation-name: makeSmaller;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s; }
  .emoji img {
    height: 100%;
    pointer-events: none; }

.react-draggable {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s; }
  .react-draggable.editing {
    transition: none; }
  .react-draggable.react-draggable-dragging {
    transition: none; }

.emojiSkin,
.emojiFront,
.emojiBack {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute; }
  .emojiSkin img,
  .emojiFront img,
  .emojiBack img {
    height: 100%; }

.dot {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  z-index: 9998;
  overflow: hidden; }
  .dot.selected .answers .answer .icon img,
  .dot.selected .iconHelper {
    opacity: 0.5; }
  .dot.selected.blue .answers .answer .icon {
    background: #7376a4; }
  .dot.selected.red .answers .answer .icon {
    background: #f9737a; }
  .dot.blue {
    background: #e5e5ef; }
    .dot.blue.selected .sliderTrack {
      background: #7376a4; }
    .dot.blue.selected .answer p {
      color: #7376a4; }
    .dot.blue .sliderTrack {
      background: #00005b; }
    .dot.blue .iconHelper {
      background-image: url("/public/img/icon-helper-blue.svg"); }
    .dot.blue .question .text {
      color: #00005b; }
    .dot.blue .answer.selected p {
      color: #00005b; }
    .dot.blue .answer.selected .icon {
      background: #00005b !important; }
    .dot.blue .answer .icon {
      background: #00005b; }
    .dot.blue .answer p {
      color: #00005b; }
  .dot.red {
    background: #fed9d9; }
    .dot.red.selected .sliderTrack {
      background: #f9737a; }
    .dot.red.selected .answer p {
      color: #f9737a; }
    .dot.red .sliderTrack {
      background: #f70000; }
    .dot.red .iconHelper {
      background-image: url("/public/img/icon-helper-red.svg"); }
    .dot.red .question .text {
      color: #f70000; }
    .dot.red .answer.selected p {
      color: #f70000; }
    .dot.red .answer.selected .icon {
      background: #f70000 !important; }
    .dot.red .answer .icon {
      background: #f70000; }
    .dot.red .answer p {
      color: #f70000; }
  .dot .sliderTrack {
    width: calc(100vw - 80px);
    height: 5px;
    position: absolute;
    top: calc(50% - 2.5px);
    border-radius: 5px; }
    @media screen and (min-width: 768px) {
      .dot .sliderTrack {
        width: 400px;
        left: calc(50% - 200px) !important; } }
  .dot .question {
    width: 100vw;
    position: absolute;
    top: 40px;
    left: calc(50% - 50vw);
    text-align: center; }
    @media screen and (min-width: 768px) {
      .dot .question {
        top: 30px; } }
    .dot .question .number {
      margin-bottom: 10px;
      white-space: nowrap; }
    .dot .question .text {
      font-size: 1.5em;
      font-family: "versalitaregular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      white-space: nowrap; }
      @media screen and (min-width: 768px) {
        .dot .question .text {
          font-size: 2em; } }
      .dot .question .text:after {
        content: '...'; }
  .dot .helper,
  .dot .definition {
    width: 50vw;
    position: absolute;
    bottom: 40px;
    left: calc(50% - 25vw);
    text-align: center;
    line-height: 1.2;
    opacity: 1;
    font-size: 0.8em; }
    @media screen and (min-width: 768px) {
      .dot .helper,
      .dot .definition {
        width: 150px;
        left: calc(50% - 75px);
        bottom: 30px; } }
  .dot .helper p {
    opacity: 0.75; }
  .dot .definition {
    opacity: 0; }
    @media screen and (min-width: 768px) {
      .dot .definition {
        width: 160px;
        left: calc(50% - 80px);
        bottom: 30px; } }
  .dot .iconHelper {
    margin-top: 2px;
    width: 1em;
    height: 1em;
    display: inline-block;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat; }
  .dot .answers {
    width: 100vw;
    height: 100px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50vw); }
    @media screen and (min-width: 768px) {
      .dot .answers {
        width: calc(500px - 40px);
        left: calc(50% - 230px); } }
    .dot .answers .answer {
      width: 100px;
      height: 100px;
      position: absolute; }
      .dot .answers .answer.selected img {
        opacity: 1 !important; }
      .dot .answers .answer.selected .iconHelper {
        opacity: 1 !important; }
      .dot .answers .answer.selected p {
        font-family: "FSMeWeb-Bold", "Helvetica Neue", "Helvetica", sans-serif; }
      .dot .answers .answer .icon {
        width: 40px;
        height: 40px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        border-radius: 40px; }
        .dot .answers .answer .icon:hover {
          cursor: pointer; }
        .dot .answers .answer .icon img {
          width: 20px;
          height: 20px;
          margin: 10px 0 0 10px; }
      .dot .answers .answer p {
        position: absolute;
        top: 100px;
        width: 90px;
        line-height: 1.2;
        font-size: 0.8em;
        text-align: center;
        margin-left: 5px; }
        .dot .answers .answer p:hover {
          cursor: pointer; }
        @media screen and (min-width: 768px) {
          .dot .answers .answer p {
            width: 120px;
            margin-left: -8px; } }
      .dot .answers .answer:nth-of-type(1) {
        left: 0; }
      .dot .answers .answer:nth-of-type(2) {
        left: calc(50% - 50px); }
      .dot .answers .answer:nth-of-type(3) {
        left: calc(100% - 100px); }

.emojiSelectorBg {
  width: 90px;
  height: 90px;
  background: #fadcdc;
  margin: 15px auto 0 auto;
  border-radius: 8px; }
  @media screen and (min-width: 375px) {
    .emojiSelectorBg {
      width: 120px;
      height: 120px; } }
  @media screen and (min-width: 768px) {
    .emojiSelectorBg {
      margin-top: 50px; } }
  .emojiSelectorBg.editing {
    opacity: 1; }
  .emojiSelectorBg.notEditing {
    opacity: 0; }

.emojiPosition {
  width: 70px;
  height: 70px;
  margin: 10px 0 0 10px; }

.emojiSelector {
  width: calc(100vw - 40px);
  padding: 10px;
  background: #fadcdc;
  border-radius: 8px;
  margin: -8px auto 0 auto;
  z-index: 10000; }
  @media screen and (min-width: 375px) {
    .emojiSelector {
      width: calc(100vw - 80px); } }
  @media screen and (min-width: 768px) {
    .emojiSelector {
      width: auto;
      width: initial; } }
  .emojiSelector.editing {
    opacity: 1; }
  .emojiSelector.notEditing {
    opacity: 0; }
  .emojiSelector .row {
    border-bottom: #FFF 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px; }
    .emojiSelector .row:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0; }
    .emojiSelector .row p {
      text-align: center;
      margin-bottom: 5px;
      font-size: 0.8em; }
      @media screen and (min-width: 375px) {
        .emojiSelector .row p {
          margin-bottom: 10px;
          font-size: 1em; } }
    .emojiSelector .row .items .item {
      padding: 0;
      border: 0;
      background: none;
      width: 30px;
      height: 30px;
      font-size: 1em;
      margin-right: 5px;
      border-radius: 6px; }
      @media screen and (min-width: 768px) {
        .emojiSelector .row .items .item {
          width: 40px;
          height: 40px; } }
      .emojiSelector .row .items .item.noRound img {
        border-radius: 0; }
      .emojiSelector .row .items .item.selected {
        background: rgba(255, 255, 255, 0.5); }
      .emojiSelector .row .items .item img {
        border-radius: 100%;
        width: 80%;
        height: 80%;
        margin-top: 4px; }

section.intro {
  text-align: center; }
  section.intro .centerContainer {
    position: relative;
    height: calc(100vh - 69px);
    height: calc((1vh * 100) - 69px);
    height: calc((var(--vh, 1vh) * 100) - 69px);
    display: flex;
    justify-content: center;
    align-items: center; }
  section.intro .emojiDismissedText {
    opacity: 0;
    position: absolute;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    height: calc((1vh * 50));
    height: calc((var(--vh, 1vh) * 50));
    pointer-events: none; }
    section.intro .emojiDismissedText.visible {
      display: block;
      opacity: 1;
      -webkit-animation-name: animMakeVisible;
              animation-name: animMakeVisible;
      -webkit-animation-duration: 0.5s;
              animation-duration: 0.5s; }
    section.intro .emojiDismissedText p {
      width: calc(100vw - 60px);
      padding: 0 30px;
      line-height: 1.3; }

#icebreakerTrigger {
  width: 100vw;
  height: 1px; }
  #icebreakerTrigger.offset {
    position: fixed;
    top: -100vh; }

.icebreaker .outroText {
  opacity: 0;
  text-align: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  pointer-events: none; }
  .icebreaker .outroText .top,
  .icebreaker .outroText .bottom {
    position: absolute; }
  .icebreaker .outroText .top {
    width: 240px;
    padding: 10px 0;
    top: 93px;
    left: calc(50% - (240px / 2));
    line-height: 1.3;
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 10%, white 90%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 );
    /* IE6-9 */ }
    @media screen and (min-width: 375px) {
      .icebreaker .outroText .top {
        top: 125px;
        width: 280px;
        left: calc(50% - (280px / 2)); } }
    @media screen and (min-width: 768px) {
      .icebreaker .outroText .top {
        top: calc(25% - (46px / 2));
        height: 46px; } }
  .icebreaker .outroText .bottom {
    top: 272px;
    height: calc(100vh - 272px - 68px);
    height: calc((1vh * 100) - 272px - 68px);
    height: calc((var(--vh, 1vh) * 100) - 272px - 68px);
    padding: 0 25px;
    line-height: 1.3;
    margin: 0 auto;
    width: calc(100vw - 50px);
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 375px) {
      .icebreaker .outroText .bottom {
        top: 328px;
        height: calc(100vh - 328px - 85px);
        height: calc((1vh * 100) - 328px - 85px);
        height: calc((var(--vh, 1vh) * 100) - 328px - 85px); } }
    @media screen and (min-width: 768px) {
      .icebreaker .outroText .bottom {
        top: calc(50vh + 75px);
        top: calc((1vh * 50) + 75px);
        top: calc((var(--vh, 1vh) * 50) + 75px);
        height: calc((1vh * 50) - 75px - 85px);
        height: calc((var(--vh, 1vh) * 50) - 75px - 85px); } }
    .icebreaker .outroText .bottom div {
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 10%, white 90%, rgba(255, 255, 255, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 );
      /* IE6-9 */
      padding: 10px 0; }
    .icebreaker .outroText .bottom br {
      display: none; }
      @media screen and (min-width: 768px) {
        .icebreaker .outroText .bottom br {
          display: inline; } }
    .icebreaker .outroText .bottom p:first-of-type {
      margin-bottom: 7px; }
      @media screen and (min-width: 768px) {
        .icebreaker .outroText .bottom p:first-of-type {
          margin-bottom: 12px; } }
    @media screen and (min-width: 768px) {
      .icebreaker .outroText .bottom p:last-of-type {
        font-size: 0.8em; } }
    .icebreaker .outroText .bottom .bigText {
      font-size: 1.25em;
      margin-bottom: 5px; }
      @media screen and (min-width: 768px) {
        .icebreaker .outroText .bottom .bigText {
          font-size: 2.5em; } }

.icebreaker .icebreakerButton {
  width: 220px;
  left: calc(50vw - 110px);
  z-index: 10000;
  opacity: 1; }

.icebreaker .icebreakerDot {
  background: #00005b; }
  .icebreaker .icebreakerDot.shrink {
    pointer-events: none;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    transition: top 1s, -webkit-transform 1s;
    transition: transform 1s, top 1s;
    transition: transform 1s, top 1s, -webkit-transform 1s; }
    @media screen and (max-width: 375px) {
      .icebreaker .icebreakerDot.shrink {
        top: 35px !important; } }
  .icebreaker .icebreakerDot p {
    width: 200px;
    text-align: center;
    position: absolute;
    left: calc(50% - 100px);
    line-height: 1.3;
    color: #FFF; }
    @media screen and (min-width: 375px) {
      .icebreaker .icebreakerDot p {
        width: 250px;
        left: calc(50% - 125px); } }
    .icebreaker .icebreakerDot p.centre {
      top: 50%;
      left: 0;
      width: 100%; }
    .icebreaker .icebreakerDot p.noWrap {
      white-space: nowrap; }

.icebreaker .icebreakerArrowLeft,
.icebreaker .icebreakerArrowRight {
  width: 35px;
  position: absolute;
  z-index: 10000;
  top: 42px; }
  @media screen and (min-width: 768px) {
    .icebreaker .icebreakerArrowLeft,
    .icebreaker .icebreakerArrowRight {
      width: 55px;
      top: 50%; } }

.icebreaker .icebreakerArrowLeft {
  left: -20px; }
  @media screen and (min-width: 768px) {
    .icebreaker .icebreakerArrowLeft {
      left: calc(50% - 200px); } }

.icebreaker .icebreakerArrowRight {
  right: -20px; }
  @media screen and (min-width: 768px) {
    .icebreaker .icebreakerArrowRight {
      right: calc(50% - 200px); } }

.icebreaker .icebreakerExamples {
  width: calc(100vw + 40px);
  height: 150px;
  position: absolute;
  left: -20px;
  top: 134px;
  opacity: 0;
  background-image: url("/public/img/icebreaker-examples.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain; }
  @media screen and (min-width: 375px) {
    .icebreaker .icebreakerExamples {
      top: 190px; } }
  @media screen and (min-width: 768px) {
    .icebreaker .icebreakerExamples {
      width: 768px;
      left: calc(50vw - 384px);
      top: calc(50vh - 75px);
      top: calc((1vh * 50) - 75px);
      top: calc((var(--vh, 1vh) * 50) - 75px);
      background-image: url("/public/img/icebreaker-example-desktop.svg"); } }

.progress {
  position: fixed;
  right: 0;
  top: 0;
  width: 3px;
  height: 100vh;
  background: rgba(247, 0, 0, 0.1);
  z-index: 10000;
  opacity: 0; }
  @media screen and (min-width: 768px) {
    .progress {
      width: 10px; } }

.progressInside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: rgba(247, 0, 0, 0.75);
  transition: height 1s; }

.segments {
  width: 3px;
  height: 100%;
  z-index: 10000;
  position: relative; }
  @media screen and (min-width: 768px) {
    .segments {
      width: 10px; } }
  .segments .segment {
    border-top: #FFF 1px solid;
    border-bottom: #FFF 1px solid; }
    .segments .segment:first-of-type {
      border-top: rgba(0, 0, 0, 0); }
    .segments .segment:last-of-type {
      border-bottom: rgba(0, 0, 0, 0); }

section.outro {
  text-align: center; }
  section.outro .top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    height: calc((1vh * 50));
    height: calc((var(--vh, 1vh) * 50));
    padding: 0 40px; }
    section.outro .top div {
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 10%, white 90%, rgba(255, 255, 255, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 );
      /* IE6-9 */
      padding: 20px 0; }
  section.outro .message {
    line-height: 1.3; }
  section.outro .showMyMatches {
    width: 200px;
    left: calc(50vw - 100px); }

.fireworks {
  position: absolute;
  bottom: 0;
  left: calc(50vw - 160px);
  width: 320px;
  height: 568px;
  z-index: 99999;
  display: none;
  pointer-events: none;
  overflow: hidden; }
  .fireworks.show {
    display: block; }
  .fireworks .fireworksStarLeft,
  .fireworks .fireworksStarMid,
  .fireworks .fireworksStarRight,
  .fireworks .fireworksStreakTop,
  .fireworks .fireworksSparkles {
    position: absolute;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .fireworks .fireworksStarLeft {
    width: 60px;
    height: 60px;
    bottom: -60px;
    left: calc(50% - (23% / 2));
    -webkit-animation-name: fireworksStarLeft;
            animation-name: fireworksStarLeft;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-delay: 2s;
            animation-delay: 2s; }
  .fireworks .fireworksStarMid {
    width: 80px;
    height: 80px;
    bottom: -80px;
    left: calc(50% - 40px);
    -webkit-animation-name: fireworksStarMid;
            animation-name: fireworksStarMid;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-delay: 2s;
            animation-delay: 2s; }
  .fireworks .fireworksStarRight {
    width: 60px;
    height: 60px;
    bottom: -60px;
    left: calc(50% - (23% / 2));
    -webkit-animation-name: fireworksStarRight;
            animation-name: fireworksStarRight;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-delay: 2s;
            animation-delay: 2s; }
  .fireworks .fireworksStreakTop {
    width: 320px;
    left: 0;
    bottom: 35%;
    opacity: 0;
    -webkit-animation-name: fireworksStreakTop;
            animation-name: fireworksStreakTop;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-delay: 2.6s;
            animation-delay: 2.6s; }
  .fireworks .fireworksSparkles {
    width: 320px;
    height: 320px;
    bottom: -320px;
    left: 0;
    opacity: 0;
    -webkit-animation-name: fireworksSparkles;
            animation-name: fireworksSparkles;
    -webkit-animation-duration: 2.5s;
            animation-duration: 2.5s;
    -webkit-animation-delay: 2s;
            animation-delay: 2s; }

@-webkit-keyframes fireworksStarLeft {
  0% {
    bottom: -60px;
    left: calc(50% - 40px); }
  50% {
    opacity: 1; }
  100% {
    bottom: 50%;
    left: 10%;
    opacity: 0; } }

@keyframes fireworksStarLeft {
  0% {
    bottom: -60px;
    left: calc(50% - 40px); }
  50% {
    opacity: 1; }
  100% {
    bottom: 50%;
    left: 10%;
    opacity: 0; } }

@-webkit-keyframes fireworksStarMid {
  0% {
    bottom: -80px;
    left: calc(50% - 40px); }
  50% {
    opacity: 1; }
  100% {
    bottom: 60%;
    left: calc(50% - 40px);
    opacity: 0; } }

@keyframes fireworksStarMid {
  0% {
    bottom: -80px;
    left: calc(50% - 40px); }
  50% {
    opacity: 1; }
  100% {
    bottom: 60%;
    left: calc(50% - 40px);
    opacity: 0; } }

@-webkit-keyframes fireworksStarRight {
  0% {
    bottom: -60px;
    left: calc(50% - 40px); }
  50% {
    opacity: 1; }
  100% {
    bottom: 50%;
    left: 70%;
    opacity: 0; } }

@keyframes fireworksStarRight {
  0% {
    bottom: -60px;
    left: calc(50% - 40px); }
  50% {
    opacity: 1; }
  100% {
    bottom: 50%;
    left: 70%;
    opacity: 0; } }

@-webkit-keyframes fireworksStreakTop {
  0% {
    bottom: 35%;
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    bottom: 60%;
    opacity: 0; } }

@keyframes fireworksStreakTop {
  0% {
    bottom: 35%;
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    bottom: 60%;
    opacity: 0; } }

@-webkit-keyframes fireworksSparkles {
  0% {
    bottom: -320px;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    bottom: 25%;
    opacity: 0; } }

@keyframes fireworksSparkles {
  0% {
    bottom: -320px;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    bottom: 25%;
    opacity: 0; } }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@font-face {
  font-family: 'versalitaregular';
  src: url("/public/typefaces/versalita-webfont.eot");
  src: url("/public/typefaces/versalita-webfont.eot?#iefix") format("embedded-opentype"), url("/public/typefaces/versalita-webfont.woff2") format("woff2"), url("/public/typefaces/versalita-webfont.woff") format("woff"), url("/public/typefaces/versalita-webfont.svg#versalitaregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FSMeWeb-Bold';
  src: url("/public/typefaces/FSMeWeb-Bold.woff2") format("woff2"), url("/public/typefaces/FSMeWeb-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FSMeWeb-Regular';
  src: url("/public/typefaces/FSMeWeb-Regular.woff2") format("woff2"), url("/public/typefaces/FSMeWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@-webkit-keyframes makeSmaller {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

@keyframes makeSmaller {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

@-webkit-keyframes makeBigger {
  from {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes makeBigger {
  from {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes animMakeVisible {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes animMakeVisible {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes animMakeInvisible {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes animMakeInvisible {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes animMakeUnfade {
  from {
    opacity: 0.2; }
  to {
    opacity: 1; } }

@keyframes animMakeUnfade {
  from {
    opacity: 0.2; }
  to {
    opacity: 1; } }

@-webkit-keyframes animMakeFade {
  from {
    opacity: 1; }
  to {
    opacity: 0.2; } }

@keyframes animMakeFade {
  from {
    opacity: 1; }
  to {
    opacity: 0.2; } }

body {
  font-family: "FSMeWeb-Regular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  color: #1c1c1c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none; }
  @media screen and (min-width: 375px) {
    body {
      font-size: 18px; } }
  body.disableScroll #root {
    overflow-y: hidden !important; }

/* Let UI settle before displaying */
#root {
  opacity: 0;
  position: fixed;
  height: calc(100vh);
  height: calc((1vh * 100));
  height: calc((var(--vh, 1vh) * 100));
  width: 100vw;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll; }
  #root.fontsLoaded {
    -webkit-animation-name: animMakeVisible;
            animation-name: animMakeVisible;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

::-moz-selection {
  color: #FFF;
  background: #212121; }

::selection {
  color: #FFF;
  background: #212121; }

/* Force hide scrollbar on WebKit */
/* https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll */
html {
  overflow: -moz-scrollbars-none;
  overflow: scroll;
  overflow-x: hidden;
  overscroll-behavior-y: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

::-webkit-scrollbar {
  width: 0px;
  background: transparent; }

::-webkit-scrollbar-thumb {
  background: #FF0000; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.bigText {
  font-family: "versalitaregular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 2.5em;
  line-height: 1 !important;
  color: #00005b; }
  @media screen and (min-width: 375px) {
    .bigText {
      font-size: 3em; } }

.bigText span {
  color: #f70000; }

.bigText span.redLighter {
  color: #ff5252; }

.bigText span.blueLighter {
  color: #0000a0; }

.button {
  font-family: "FSMeWeb-Bold", "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 1em;
  border: 0;
  padding: 12px 0;
  position: absolute;
  z-index: 10000;
  opacity: 1;
  border-radius: 8px;
  transition: opacity 0.25s;
  background: #ff5252;
  color: #FFF; }

.button:disabled,
.button[disabled] {
  opacity: 0; }

.secondaryButton {
  font-family: "FSMeWeb-Regular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  text-align: center;
  position: absolute;
  left: calc(50vw - 37.5vw);
  bottom: 15px;
  width: 75vw;
  z-index: 10000;
  background: #FFF;
  border: 0;
  padding-bottom: 5px; }

/* Helpers */
.hide {
  display: none; }

.noInteract {
  pointer-events: none; }

/* Animation */
.visible {
  opacity: 1;
  -webkit-animation-name: animMakeVisible;
          animation-name: animMakeVisible;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.visibleDelay {
  opacity: 1;
  -webkit-animation-name: animMakeVisible;
          animation-name: animMakeVisible;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.invisible {
  opacity: 0;
  -webkit-animation-name: animMakeInvisible;
          animation-name: animMakeInvisible;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.error {
  border-radius: 8px;
  background: #FFF;
  border: #ff5252 2px solid;
  padding: 10px;
  margin: 10px;
  position: fixed;
  bottom: 60px;
  left: 0;
  text-align: center;
  line-height: 1.3;
  z-index: 10000; }
  @media screen and (min-width: 768px) {
    .error {
      margin: 10px 0 0 0;
      width: 500px;
      left: calc(50vw - 254px); } }
  .error p:first-of-type {
    font-family: "FSMeWeb-Bold", "Helvetica Neue", "Helvetica", sans-serif;
    color: #ff5252; }
  .error button {
    font-size: 1em;
    font-family: "FSMeWeb-Regular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    color: #1c1c1c;
    text-decoration: underline;
    border: 0;
    background: none; }

