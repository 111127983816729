// Colours
$color-copy: #1c1c1c;
$color-red-pale: #fadcdc;
$color-red-light: #fed9d9;
$color-red-lightish: #ff5252;
$color-red-dark: #f70000;
$color-blue-light: #e5e5ef;
$color-blue-lightish: #0000a0;
$color-blue-dark: #00005b;

// Font stacks
$font-stack-display: 'versalitaregular', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-stack-copy-regular: 'FSMeWeb-Regular', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-stack-copy-bold: 'FSMeWeb-Bold', 'Helvetica Neue', 'Helvetica', sans-serif;

// Line height
$line-height-display: 1.2;
$line-height-copy: 1.3;

// Styles
$border-radius: 8px;
