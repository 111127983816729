@import '../sass/config.scss';
@import '../sass/mixins.scss';

section.intro {
  text-align: center;

  .centerContainer {
    position: relative;
    height: calc(100vh - 69px);
    height: calc((var(--vh, 1vh) * 100) - 69px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .emojiDismissedText {
    opacity: 0;
    position: absolute;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    height: calc((var(--vh, 1vh) * 50));
    pointer-events: none;

    &.visible {
      display: block;
      opacity: 1;
      animation-name: animMakeVisible;
      animation-duration: 0.5s;
    }

    p {
      width: calc(100vw - 60px);
      padding: 0 30px;
      line-height: $line-height-copy;
    }
  }
}
