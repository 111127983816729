@font-face {
  font-family: 'versalitaregular';
  src: url('/public/typefaces/versalita-webfont.eot');
  src: url('/public/typefaces/versalita-webfont.eot?#iefix') format('embedded-opentype'),
       url('/public/typefaces/versalita-webfont.woff2') format('woff2'),
       url('/public/typefaces/versalita-webfont.woff') format('woff'),
       url('/public/typefaces/versalita-webfont.svg#versalitaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FSMeWeb-Bold';
  src: url('/public/typefaces/FSMeWeb-Bold.woff2') format('woff2'),
       url('/public/typefaces/FSMeWeb-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FSMeWeb-Regular';
 src: url('/public/typefaces/FSMeWeb-Regular.woff2') format('woff2'),
      url('/public/typefaces/FSMeWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
