@keyframes makeSmaller {
  from { transform: scale(1); }
  to { transform: scale(0.8); }
}

@keyframes makeBigger {
  from { transform: scale(0.8); }
  to { transform: scale(1); }
}

@keyframes animMakeVisible {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes animMakeInvisible {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes animMakeUnfade {
  from { opacity: 0.2; }
  to { opacity: 1; }
}

@keyframes animMakeFade {
  from { opacity: 1; }
  to { opacity: 0.2; }
}
