@import './sass/config.scss';
@import './sass/mixins.scss';

body {
  font-family: $font-stack-copy-regular;
  font-size: 16px;
  color: $color-copy;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;

  @include media(mobile) {
    font-size: 18px;
  }

  &.disableScroll {
    #root {
      overflow-y: hidden !important;
    }
  }
}

/* Let UI settle before displaying */
#root {
  opacity: 0;
  position: fixed;
  height: calc(100vh);
  height: calc((var(--vh, 1vh) * 100));
  width: 100vw;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;

  &.fontsLoaded {
    animation-name: animMakeVisible;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
}

::selection {
  color: #FFF;
  background: #212121;
}

/* Force hide scrollbar on WebKit */
/* https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll */
html {
  overflow: -moz-scrollbars-none;
  overflow: scroll;
  overflow-x: hidden;
  overscroll-behavior-y: none;

  // Disable text selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #FF0000;
}

button {
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

.bigText {
  font-family: $font-stack-display;
  font-size: 2.5em;
  line-height: 1 !important;
  color: $color-blue-dark;

  @include media(mobile) {
    font-size: 3em;
  }
}

.bigText span {
  color: $color-red-dark;
}

.bigText span.redLighter {
  color: $color-red-lightish;
}

.bigText span.blueLighter {
  color: $color-blue-lightish;
}

.button {
  font-family: $font-stack-copy-bold;
  font-size: 1em;
  border: 0;
  padding: 12px 0;
  position: absolute;
  z-index: 10000;
  opacity: 1;
  border-radius: 8px;
  transition: opacity 0.25s;
  background: $color-red-lightish;
  color: #FFF;
}

.button:disabled,
.button[disabled] {
  opacity: 0;
}

.secondaryButton {
  font-family: $font-stack-copy-regular;
  font-size: 1em;
  text-align: center;
  position: absolute;
  left: calc(50vw - 37.5vw);
  bottom: 15px;
  width: 75vw;
  z-index: 10000;
  background: #FFF;
  border: 0;
  padding-bottom: 5px;
}

/* Helpers */
.hide {
  display: none;
}

.noInteract {
  pointer-events: none;
}

/* Animation */
.visible {
  opacity: 1;
  animation-name: animMakeVisible;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.visibleDelay {
  opacity: 1;
  animation-name: animMakeVisible;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.invisible {
  opacity: 0;
  animation-name: animMakeInvisible;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.error {
  border-radius: $border-radius;
  background: #FFF;
  border: #{$color-red-lightish} 2px solid;
  padding: 10px;
  margin: 10px;
  position: fixed;
  bottom: 60px;
  left: 0;
  text-align: center;
  line-height: $line-height-copy;
  z-index: 10000;

  @include media(tablet) {
    margin: 10px 0 0 0;
    width: 500px;
    left: calc(50vw - 254px);
  }

  p:first-of-type {
    font-family: $font-stack-copy-bold;
    color: $color-red-lightish;
  }

  button {
    font-size: 1em;
    font-family: $font-stack-copy-regular;
    color: $color-copy;
    text-decoration: underline;
    border: 0;
    background: none;
  }
}
