@import '../sass/mixins';

.progress {
  position: fixed;
  right: 0;
  top: 0;
  width: 3px;
  height: 100vh;
  background: rgba(247, 0, 0, 0.1);
  z-index: 10000;
  opacity: 0;

  @include media(tablet) {
    width: 10px;
  }
}

.progressInside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: rgba(247, 0, 0, 0.75);
  -webkit-transition: height 1s;
  transition: height 1s;
}

.segments {
  width: 3px;
  height: 100%;
  z-index: 10000;
  position: relative;

  @include media(tablet) {
    width: 10px;
  }

  .segment {
    border-top: #FFF 1px solid;
    border-bottom: #FFF 1px solid;

    &:first-of-type {
      border-top: rgba(0, 0, 0, 0);
    }

    &:last-of-type {
      border-bottom: rgba(0, 0, 0, 0);
    }
  }
}
