@import '../sass/mixins';

.emoji {
  width: 70px;
  height: 70px;
  z-index: 9999;
  transform: scale(1);
  text-align: center;
  position: relative;

  @include media(mobile) {
    width: 100px;
    height: 100px;
  }

  &.unselected {
    transform: scale(1);
    animation-name: makeBigger;
    animation-duration: 0.5s;
  }

  &.selected {
    transform: scale(0.8);
    animation-name: makeSmaller;
    animation-duration: 0.5s;
  }

  img {
    height: 100%;
    pointer-events: none;
  }
}

.react-draggable {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: transform 1s;
  transition: transform 1s;

  &.editing {
    -webkit-transition: none;
    transition: none;
  }

  &.react-draggable-dragging {
    -webkit-transition: none;
    transition: none;
  }
}

.emojiSkin,
.emojiFront,
.emojiBack {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;

  img {
    height: 100%;
  }
}
