@import '../sass/config.scss';
@import '../sass/mixins.scss';

.emojiSelectorBg {
  width: 90px;
  height: 90px;
  background: $color-red-pale;
  margin: 15px auto 0 auto;
  border-radius: $border-radius;

  @include media(mobile) {
    width: 120px;
    height: 120px;
  }

  @include media(tablet) {
    margin-top: 50px;
  }

  &.editing {
    opacity: 1;
  }

  &.notEditing {
    opacity: 0;
  }
}

.emojiPosition {
  width: 70px;
  height: 70px;
  margin: 10px 0 0 10px;
}

.emojiSelector {
  width: calc(100vw - 40px);
  padding: 10px;
  background: $color-red-pale;
  border-radius: $border-radius;
  margin: -8px auto 0 auto;
  z-index: 10000;

  @include media(mobile) {
    width: calc(100vw - 80px);
  }

  @include media(tablet) {
    width: initial;
  }

  &.editing {
    opacity: 1;
  }

  &.notEditing {
    opacity: 0;
  }

  .row {
    border-bottom: #FFF 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    p {
      text-align: center;
      margin-bottom: 5px;
      font-size: 0.8em;

      @include media(mobile) {
        margin-bottom: 10px;
        font-size: 1em;
      }
    }

    .items {
      .item {
        padding: 0;
        border: 0;
        background: none;
        width: 30px;
        height: 30px;
        font-size: 1em;
        margin-right: 5px;
        border-radius: 6px;

        @include media(tablet) {
          width: 40px;
          height: 40px;
        }

        &.noRound {
          img {
            border-radius: 0;
          }
        }

        &.selected {
          background: rgba(255, 255, 255, 0.5);
        }

        img {
          border-radius: 100%;
          width: 80%;
          height: 80%;
          margin-top: 4px;
        }
      }
    }
  }
}
