@import '../sass/config.scss';
@import '../sass/mixins.scss';

$selected-blue: #7376a4;
$selected-red: #f9737a;

.dot {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  z-index: 9998;
  overflow: hidden;

  &.selected {
    .answers .answer .icon img,
    .iconHelper {
      opacity: 0.5;
    }

    &.blue {
      .answers .answer .icon {
        background: $selected-blue;
      }
    }

    &.red {
      .answers .answer .icon {
        background: $selected-red;
      }
    }
  }

  &.blue {
    background: $color-blue-light;

    &.selected {
      .sliderTrack {
        background: $selected-blue;
      }

      .answer p {
        color: $selected-blue;
      }
    }

    .sliderTrack {
      background: $color-blue-dark;
    }

    .iconHelper {
      background-image: url('/public/img/icon-helper-blue.svg');
    }

    .question {
      .text {
        color: $color-blue-dark;
      }
    }

    .answer {
      &.selected {
        p {
          color: $color-blue-dark;
        }

        .icon {
          background: $color-blue-dark !important;
        }
      }

      .icon {
        background: $color-blue-dark;
      }

      p {
        color: $color-blue-dark;
      }
    }
  }

  &.red {
    background: $color-red-light;

    &.selected {
      .sliderTrack {
        background: $selected-red;
      }

      .answer p {
        color: $selected-red;
      }
    }

    .sliderTrack {
      background: $color-red-dark;
    }

    .iconHelper {
      background-image: url('/public/img/icon-helper-red.svg');
    }

    .question {
      .text {
        color: $color-red-dark;
      }
    }

    .answer {
      &.selected {
        p {
          color: $color-red-dark;
        }

        .icon {
          background: $color-red-dark !important;
        }
      }

      .icon {
        background: $color-red-dark;
      }

      p {
        color: $color-red-dark;
      }
    }
  }

  .sliderTrack {
    width: calc(100vw - 80px);
    height: 5px;
    position: absolute;
    top: calc(50% - 2.5px);
    border-radius: 5px;

    @include media(tablet) {
      width: 400px;
      left: calc(50% - 200px) !important;
    }
  }

  .question {
    width: 100vw;
    position: absolute;
    top: 40px;
    left: calc(50% - 50vw);
    text-align: center;

    @include media(tablet) {
      top: 30px;
    }

    .number {
      margin-bottom: 10px;
      white-space: nowrap;
    }

    .text {
      font-size: 1.5em;
      font-family: $font-stack-display;
      white-space: nowrap;

      @include media(tablet) {
        font-size: 2em;
      }

      &:after {
        content: '...';
      }
    }
  }

  .helper,
  .definition {
    width: 50vw;
    position: absolute;
    bottom: 40px;
    left: calc(50% - 25vw);
    text-align: center;
    line-height: 1.2;
    opacity: 1;
    font-size: 0.8em;

    @include media(tablet) {
      width: 150px;
      left: calc(50% - 75px);
      bottom: 30px;
    }
  }

  .helper p {
    opacity: 0.75;
  }

  .definition {
    opacity: 0;

    @include media(tablet) {
      width: 160px;
      left: calc(50% - 80px);
      bottom: 30px;
    }
  }

  .iconHelper {
    margin-top: 2px;
    width: 1em;
    height: 1em;
    display: inline-block;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .answers {
    width: 100vw;
    height: 100px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50vw);

    @include media(tablet) {
      width: calc(500px - 40px);
      left: calc(50% - 230px);
    }

    .answer {
      width: 100px;
      height: 100px;
      position: absolute;

      &.selected {
        img {
          opacity: 1 !important;
        }

        .iconHelper {
          opacity: 1 !important;
        }

        p {
          font-family: $font-stack-copy-bold;
        }
      }

      .icon {
        width: 40px;
        height: 40px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        border-radius: 40px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 20px;
          height: 20px;
          margin: 10px 0 0 10px;
        }
      }

      p {
        position: absolute;
        top: 100px;
        width: 90px;
        line-height: 1.2;
        font-size: 0.8em;
        text-align: center;
        margin-left: 5px;

        &:hover {
          cursor: pointer;
        }

        @include media(tablet) {
          width: 120px;
          margin-left: -8px;
        }
      }

      &:nth-of-type(1) {
        left: 0;
      }

      &:nth-of-type(2) {
        left: calc(50% - 50px);
      }

      &:nth-of-type(3) {
        left: calc(100% - 100px);
      }
    }
  }
}
