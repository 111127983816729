@mixin media($size: false) {
  @if $size == desktop {
    @media screen and (min-width: 1025px) {
      @content;
    }
  }

  @else if $size == tablet {
    @media screen and (min-width: 768px) {
      @content;
    }
  }

  @else if $size == mobile {
    @media screen and (min-width: 375px) {
      @content;
    }
  }

  @else if $size == xs_mobile {
    @media screen and (max-width: 375px) {
      @content;
    }
  }
}

@mixin white-gradient-bg() {
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 10%,rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 10%,rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}
