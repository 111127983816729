@import '../sass/config.scss';
@import '../sass/mixins.scss';

#icebreakerTrigger {
  width: 100vw;
  height: 1px;

  &.offset {
    position: fixed;
    top: -100vh;
  }
}

.icebreaker {
  .outroText {
    opacity: 0;
    text-align: center;
    position: absolute;
    height: 100vh;
    width: 100vw;
    pointer-events: none;

    .top,
    .bottom {
      position: absolute;
    }

    .top {
      width: 240px;
      padding: 10px 0;
      top: 93px;
      left: calc(50% - (240px / 2));
      line-height: $line-height-copy;
      @include white-gradient-bg();

      @include media(mobile) {
        top: 125px;
        width: 280px;
        left: calc(50% - (280px / 2));
      }

      @include media(tablet) {
        top: calc(25% - (46px / 2));
        height: 46px;
      }
    }

    .bottom {
      top: 272px;
      height: calc(100vh - 272px - 68px);
      height: calc((var(--vh, 1vh) * 100) - 272px - 68px);
      padding: 0 25px;
      line-height: $line-height-copy;
      margin: 0 auto;
      width: calc(100vw - 50px);
      display: flex;
      justify-content: center;
      align-items: center;

      @include media(mobile) {
        top: 328px;
        height: calc(100vh - 328px - 85px);
        height: calc((var(--vh, 1vh) * 100) - 328px - 85px);
      }

      @include media(tablet) {
        top: calc(50vh + 75px);
        top: calc((var(--vh, 1vh) * 50) + 75px);
        height: calc((var(--vh, 1vh) * 50) - 75px - 85px);
      }

      div {
        @include white-gradient-bg();
        padding: 10px 0;
      }

      br {
        display: none;

        @include media(tablet) {
          display: inline;
        }
      }

      p:first-of-type {
        margin-bottom: 7px;

        @include media(tablet) {
          margin-bottom: 12px;
        }
      }

      p:last-of-type {
        @include media(tablet) {
          font-size: 0.8em;
        }
      }

      .bigText {
        font-size: 1.25em;
        margin-bottom: 5px;

        @include media(tablet) {
          font-size: 2.5em;
        }
      }
    }
  }

  .icebreakerButton {
    width: 220px;
    left: calc(50vw - 110px);
    z-index: 10000;
    opacity: 1;
  }

  .icebreakerDot {
    background: $color-blue-dark;

    &.shrink {
      pointer-events: none;
      transform: scale(0.3);
      -webkit-transition: transform 1s, top 1s;
      transition: transform 1s, top 1s;

      @include media(xs_mobile) {
        top: 35px !important;
      }
    }

    p {
      width: 200px;
      text-align: center;
      position: absolute;
      left: calc(50% - 100px);
      line-height: $line-height-copy;
      color: #FFF;

      @include media(mobile) {
        width: 250px;
        left: calc(50% - 125px);
      }

      &.centre {
        top: 50%;
        left: 0;
        width: 100%;
      }

      &.noWrap {
        white-space: nowrap;
      }
    }
  }

  .icebreakerArrowLeft,
  .icebreakerArrowRight {
    width: 35px;
    position: absolute;
    z-index: 10000;
    top: 42px;

    @include media(tablet) {
      width: 55px;
      top: 50%;
    }
  }

  .icebreakerArrowLeft {
    left: -20px;

    @include media(tablet) {
      left: calc(50% - 200px);
    }
  }

  .icebreakerArrowRight {
    right: -20px;

    @include media(tablet) {
      right: calc(50% - 200px);
    }
  }

  .icebreakerExamples {
    width: calc(100vw + 40px);
    height: 150px;
    position: absolute;
    left: -20px;
    top: 134px;
    opacity: 0;
    background-image: url('/public/img/icebreaker-examples.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    @include media(mobile) {
      top: 190px;
    }

    @include media(tablet) {
      width: 768px;
      left: calc(50vw - 384px);
      top: calc(50vh - 75px);
      top: calc((var(--vh, 1vh) * 50) - 75px);
      background-image: url('/public/img/icebreaker-example-desktop.svg');
    }
  }
}
